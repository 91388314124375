// // import necessary images
// import b1 from '../images/i1.png';
// import b2 from '../images/i2.png';
// import b3 from '../images/i3.png';
// import b4 from '../images/i4.png';
// import b5 from '../images/i5.png';
// import b6 from '../images/i6.jpg';

// import i1 from '../images/icon_ae.gif';
// import i2 from '../images/icon2.png';
// import i3 from '../images/icon3.png';

// import p1 from '../images/pic1.png';

// import nL_img from '../assets/nsbe_logo.jpg';

// // import necessary libraries
// import React from 'react';
// import { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import Footer from './Footer';

// const images = [b1, b2, b3, b4, b5, b6];

// function Home() {
//     // Declare and initialize background image state
//     const [backgroundImage, setBackgroundImage] = useState("");

//     // Set background image
//     useEffect(() => {
//         let counter = 0;
//         const interval = setInterval(() => {
//             setBackgroundImage(images[counter]);
//             counter = (counter + 1) % images.length;
//         }, 7000);

//         // Cleanup on component unmount
//         return () => clearInterval(interval);
//     }, []); // Empty dependency array means this effect runs only once on mount

//     // // Function to change background image
//     // var counter = 0;
//     // setInterval(function(){
//     //     if(counter < images.length){
//     //         setBackgroundImage(`${images[counter]}`);
//     //         counter++;
//     //     }
//     //     else{
//     //         counter = 0;
//     //         setBackgroundImage(`${images[counter]}`);
//     //         counter++;
//     //     }
//     // }, 7000);

//     return (
//         <>
//             <div className="bg-gray-50 dark:bg-gray-900"
//             >
//                 <section className="section1 mb-24" id='about'
//                     style={{
//                         backgroundImage: `linear-gradient(rgba(1.5,0,0,1.5),rgba(0,0,0,0.7) ), url(${backgroundImage})`,
//                         backgroundSize: 'cover',
//                         backgroundPosition: 'center',
//                         backgroundRepeat: 'no-repeat',
//                         height: '100vh',
//                         display: 'flex',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         margin: 'auto'
//                     }}>
//                     <div className="about-container w-4/5 mx-auto my-auto ">
//                         <h1 className="font-extrabold text-center dark:text-white"
//                             style={{
//                                 fontSize: '7rem',
//                                 color: '#004D40',
//                                 margin: '10px',
//                                 justifyContent: 'center',
//                                 display: 'flex',
//                                 lineHeight: '1.0',
//                                 fontFamily: 'impact',
//                                 textShadow: '2px 2px 9px rgba(0, 0, 0, 0.9)'
//                             }}>
//                             NATIONAL SOCEITY OF BLACK ENGINEERS </h1>

//                         <h1 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white"
//                             style={{
//                                 fontSize: '4rem',
//                                 color: 'white',
//                                 margin: '10px',
//                                 justifyContent: 'center',
//                                 display: 'flex',
//                                 lineHeight: '1.0',
//                                 fontFamily: 'impact',
//                                 textShadow: '2px 2px 9px rgba(0, 0, 0, 0.9)'
//                             }}>
//                             UCALGARY CHAPTER </h1>

//                         {/* <h2 className="about-head text-center dark:text-white text-2xl font-extrabold"
//                 style={{fontSize: '3rem',
//                         color: '#892be2',
//                         paddingTop: '40px',
//                         textShadow: '2px 2px 9px rgba(0, 0, 0, 0.9)'}}>
//                     ABOUT US
//                 </h2> */}

//                         <div className="join-info text-center flex flex-row dark:text-white text-lg" style={{ margin: '80px' }}>

//                             <div className="become-member text-center dark:text-white text-lg bg-[#303C55] transition hover:-translate-y-1 hover:scale-80 ease-in-out delay-100 text-white bg-emerald-900 font-extrabold text-xl tracking-wider hover:shadow-xl hover:bg-[#004D40] font-medium px-6 py-4 text-center dark:bg-teal-700 dark:hover:bg-teal-900 text-white font-nunito w-[25vw] h-[7.5vh] text-2xl mx-auto flex justify-center items-center rounded-2xl shadow-lg"
//                                 style={{
//                                     fontSize: "2rem",
//                                     fontFamily: "nunito",
//                                     boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.9)',
//                                     fontWeight: "bold",
//                                 }}>
//                                 <Link to="https://docs.google.com/forms/d/e/1FAIpQLSfplx30HQHxu1OfNIjlSgTWFdMEh1KOWg6SuE553nPf1osPXA/viewform" target="_blank"> <i className="fas fa-users mr-2"></i>Become A Member </Link>
//                             </div>

//                             <div className="become-sponsor text-center dark:text-white text-lg bg-[#303C55] transition hover:-translate-y-1 hover:scale-80 ease-in-out delay-100 text-white bg-emerald-900 font-extrabold text-xl tracking-wider hover:shadow-xl hover:bg-[#004D40] font-medium px-6 py-4 text-center dark:bg-teal-700 dark:hover:bg-teal-900 text-white font-nunito w-[25vw] h-[7.5vh] text-2xl mx-auto flex justify-center items-center rounded-2xl shadow-lg"
//                                 style={{
//                                     fontSize: "2rem",
//                                     fontFamily: "nunito",
//                                     boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.9)',
//                                     fontWeight: "bold",
//                                 }}>
//                                 <Link to="/GetInvolved"> <i className="fas fa-handshake mr-2"></i>Become A Sponsor</Link>
//                             </div>

//                         </div>
//                         {/* <div className="history-header">
//                     <p className="about-info text-center dark:text-white "
//                     style={{fontSize: '1.1rem', color: 'white', margin:'30px', fontWeight: '800', lineHeight: '1.8'}}>At NSBE UCalgary, we're dedicated to empowering and inspiring Black students and professionals in Science, Technology, Engineering, and Mathematics (STEM). As a proud chapter of the National Society of Black Engineers (NSBE), we're committed to our mission of increasing the number of culturally responsible Black engineers who excel academically, succeed professionally, and positively impact their communities.
//                         Whether you're a student aspiring to pursue a career in STEM, a seasoned professional looking to advance in your field, or an ally committed to promoting diversity and inclusion, NSBE UCalgary welcomes you with open arms.
//                         </p>
//                 </div> */}
//                     </div>
//                 </section>
//                 <div className="w-full h-4 bg-[#004D40]"></div> {/* Green line */}

//                 <div className="offer-head flex flex-col text-center justify-center mx-auto my-auto">
//                     <h1 style={{
//                         fontSize: '5rem',
//                         fontFamily: 'impact',
//                         color: '#303C55',
//                         paddingTop: '60px',

//                         textShadow: '2px 2px 9px rgba(0, 0, 0, 0.5)'
//                     }}
//                     >WHAT WE OFFER</h1>
//                     <div className="w-1/3 h-1 bg-[#303C55] mx-auto mt-4"></div> {/* Line after heading */}
//                 </div>
//                 <div className="flex flex-row justify-center items-start gap-4 mx-30 my-auto py-12 h-auto">

//                     <div className=" flex flex-col mt-4 mx-auto w-4/5 " style={{ fontFamily: 'nunito' }}>
//                         <img src={i2} alt="icon2" className="w-1/3 mx-auto" />
//                         <div className='offer-head text-center mx-auto my-auto'>
//                             <h2 style={{
//                                 fontSize: '2rem',
//                                 fontFamily: 'nunito',
//                                 color: '#004D40',
//                                 textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
//                                 fontWeight: 'bold',

//                             }}>Professional Development</h2>
//                         </div>
//                         <div>
//                             <p className='offer-info text-center w-2/3 mx-auto my-auto' style={{ fontFamily: 'nunito' }}>
//                                 Innovation at the National Society of Black Engineers (NSBE) at the University of Calgary invloves fostering a collaborative environment where diverse perspectives come together to develop creative solutions to address challenges and opportunities in the engineering field.
//                                 This includes the application of engineering principles to solve real-world problems.
//                             </p>
//                         </div>
//                     </div>

//                     <div className=" flex flex-col mt-4 mx-auto w-4/5 ">
//                         <img src={i1} alt="icon1" className="w-2/3 mx-auto" />

//                         <div className='offer-head text-center mx-auto my-auto'>
//                             <h2 style={{
//                                 fontSize: '2rem',
//                                 fontFamily: 'nunito',
//                                 color: '#004D40',
//                                 textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
//                                 fontWeight: 'bold',

//                             }}>
//                                 Academic Excellence </h2>
//                         </div>
//                         <div className='offer-info text-center w-2/3 mx-auto my-auto ' style={{ fontFamily: 'nunito' }}>
//                             <p>
//                                 Academic Excellence in the National Society of Black Engineers (NSBE) at the University of Calgary refers to the efforts of the NSBE chapter to promote the highest standards of academic excellence in engineering.
//                                 This includes the application of engineering principles to solve real-world problems partaining to the black community.
//                             </p>
//                         </div>
//                     </div>

//                     <div className=" flex flex-col mt-4 mx-auto w-4/5 ">
//                         <img src={i3} alt="icon3" className="w-1/3 mx-auto" />
//                         <div className='offer-head text-center mx-auto my-auto'>
//                             <h2 style={{
//                                 fontSize: '2rem',
//                                 fontFamily: 'nunito',
//                                 color: '#004D40',
//                                 textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
//                                 fontWeight: 'bold',

//                             }}>Diversty and Community</h2>
//                         </div>
//                         <div>
//                             <p className='offer-info text-center w-2/3 mx-auto my-auto' style={{ fontFamily: 'nunito' }}>
//                                 Diversity in the National Society of Black Engineers (NSBE) at the University of Calgary refers to the inclusion and representation of individuals from various backgrounds and experiences.
//                                 This encompasses not only racial and ethnic diversity but also diversity in terms of gender, socioeconomic status, academic discipline, and cultural perspectives.
//                             </p>
//                         </div>
//                     </div>

//                     {/* <div className=" flex flex-col mt-4 mx-auto w-4/5" style={{backgroundColor: '#c375d4',
//                                                                                             height: '70vh',
//                                                                                             borderRadius: '110px',
//                                                                                             width: '28vw',
//                                                                                             boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
//                                                                                             border: '15px solid #004D40'
//                                                                                             }}>
//                     <h2 className="offer-head h-2/5 text-center dark:text-white font-extrabold"
//                         style={{fontSize: '2rem',
//                                 color: 'black',
//                                 margin: '20px',
//                                 justifyContent: 'center',
//                                 display: 'flex',
//                                 alignItems: 'center',
//                                 textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)'
//                                 }}>OUR MISSION</h2>
//                     <div className="offer-info text-center dark:text-white text-lg mx-auto" style={{paddingTop: '10px'}}>
//                         <p className="offer-info-text" style={{fontSize: '1rem', color: '#004D40', margin:'30px', fontWeight: '600' }}>The mission of the NSBE University of Calgary Chapter is to empower and inspire black engineering students through professional development, community engagement, and academic excellence. We are dedicated to creating an inclusive environment that fosters diversity, innovation, and leadership within the engineering community.
//                             By providing opportunities for networking, mentorship, and outreach, we aim to support the academic and professional growth of our members and contribute to a more equitable and diverse engineering workforce.</p>
//                     </div>
//                 </div>

//                 <div className=" flex flex-col mt-4 mx-auto w-4/5" style={{backgroundColor: '#c375d4',
//                                                                                             height: '70vh',
//                                                                                             borderRadius: '110px',
//                                                                                             width: '28vw',
//                                                                                             boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
//                                                                                             border: '15px solid #004D40'
//                                                                                             }}>
//                     <h2 className="offer-head h-2/5 text-center dark:text-white font-extrabold "
//                         style={{fontSize: '2rem',
//                                 color: 'black',
//                                 margin: '20px',
//                                 justifyContent: 'center',
//                                 display: 'flex',
//                                 alignItems: 'center',
//                                 textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)'}}>OUR VISION</h2>
//                     <div className="offer-info  text-center dark:text-white text-lg mx-auto" style={{paddingTop: '10px'}}>
//                         <p className="offer-info-text" style={{fontSize: '1rem', color: '#004D40', margin:'30px', fontWeight: '600'}}>Our vision is to cultivate a vibrant and supportive community where black engineering students at the University of Calgary can thrive and excel. We aspire to be a leading chapter in promoting diversity and inclusion within the engineering field, bridging the gap between underrepresented students and the industry. Through strategic partnerships, impactful programs, we aim to inspire the next generation of black engineers to achieve their full potential and make meaningful contributions to society.</p>
//                     </div>
//                 </div>

//                 <div className=" flex flex-col mt-4 mx-auto w-4/5" style={{backgroundColor: '#c375d4',
//                                                                                             height: '70vh',
//                                                                                             borderRadius: '110px',
//                                                                                             width: '28vw',
//                                                                                             boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
//                                                                                             border: '15px solid #004D40'}}>
//                     <h2 className="offer-head h-3/6 text-center dark:text-white font-extrabold"
//                         style={{fontSize: '2rem',
//                                 color: 'black',
//                                 margin: '20px',
//                                 justifyContent: 'center',
//                                 display: 'flex',
//                                 alignItems: 'center',
//                                 textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)'}}>OUR CORE VALUES</h2>
//                     <div className="offer-info h-3/5 text-center dark:text-white" style={{paddingTop: '1px'}}>
//                         <ul className="offer-info-text" style={{fontSize: '1.5rem', color: '#004D40',listStylePosition: 'inside', fontFamily: 'impact'}}>
//                             <li>DIVERSITY</li>
//                             <li>EXCELLENCE</li>
//                             <li>INNOVATION</li>
//                             <li>EMPOWERMENT</li>
//                             <li>LEADERSHIP</li>
//                             <li>COMMUNITY</li>
//                             <li>SERVICE</li>
//                         </ul>
//                     </div>
//                 </div> */}
//                 </div>
//                 <div className="w-full h-2 bg-[#303C55]"></div> {/* Green line */}

//                 <div className="sponsors flex flex-col text-center justify-center mx-auto my-auto bg-[#303C55] " style={{ height: 'auto' }}>
//                     <div className="sponsor-head flex flex-col text-center justify-center mx-auto my-auto">
//                         <h1 style={{
//                             fontSize: '5rem',
//                             fontFamily: 'impact',
//                             color: '#fff',
//                             paddingTop: '60px',
//                             textShadow: '2px 2px 9px rgba(0, 0, 0, 0.5)'
//                         }}>
//                             OUR SPONSORS
//                         </h1>
//                     </div>
//                     <div className="w-1/3 h-1 bg-[#303C55] mx-auto mt-4"></div> {/* Line after heading */}
//                     {/* <div className = "quick-thank-you">
//                 <p style={{fontSize: '1.3rem',
//                             color: 'black',
//                             paddingTop: '20px'
//                             }}
//                 >We are grateful for the support of our sponsors who help us achieve our mission and make a difference in the community. Thank you for your generosity and commitment to diversity and inclusion in STEM!</p>
//             </div> */}
//                     <div className="sponsor-logos grid grid-cols-2 gap-4 justify-center items-start py-12" style={{ height: 'auto' }}>
//                         <div className="platinum-tier flex flex-col mt-3 mx-auto w-4/5 ">
//                             {/* <img src={b1} alt="sponsor1" className="w-1/3 mx-auto"/> */}
//                             <div className='platinum-tier-header'>
//                                 <h2 style={{
//                                     fontSize: '2.5rem',
//                                     fontFamily: 'nunito',
//                                     textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
//                                     color: '#000',
//                                     fontWeight: 'bold'
//                                 }}>PLATINUM SPONSORS</h2>
//                                 <div className="circle bg-[#E5E4E2] rounded-full mx-auto mt-4"
//                                     style={{
//                                         width: '20rem',
//                                         height: '20rem',
//                                         // borderRadius: '1rem'
//                                     }}
//                                 ></div> {/* Circle */}
//                             </div>
//                         </div>

//                         <div className="gold-tier flex flex-col mt-4 mx-auto w-4/5 ">
//                             {/* <img src={b1} alt="sponsor1" className="w-1/3 mx-auto"/> */}
//                             <div className='gold-tier-header'>
//                                 <h2 style={{
//                                     fontSize: '2.5rem',
//                                     fontFamily: 'nunito',
//                                     color: '#000',
//                                     textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
//                                     fontWeight: 'bold',
//                                     fontWeight: 'bold'
//                                 }}>GOLD SPONSORS</h2>
//                                 <div className="circle bg-[#FFD700] rounded-full mx-auto mt-4"
//                                     style={{
//                                         width: '20rem',
//                                         height: '20rem',
//                                         // borderRadius: '1rem'
//                                     }}
//                                 ></div> {/* Circle */}
//                             </div>
//                         </div>

//                         <div className="silver-tier flex flex-col mt-4 mx-auto w-4/5 ">
//                             {/* <img src={b1} alt="sponsor1" className="w-1/3 mx-auto"/> */}
//                             <div className='silver-tier-header'>
//                                 <h2 style={{
//                                     fontSize: '2.5rem',
//                                     fontFamily: 'nunito',
//                                     textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
//                                     color: '#000',
//                                     fontWeight: 'bold'
//                                 }}>SILVER SPONSORS</h2>
//                                 <div className="circle bg-[#C0C0C0] rounded-full mx-auto mt-4"
//                                     style={{
//                                         width: '20rem',
//                                         height: '20rem',
//                                         // borderRadius: '1rem'
//                                     }}
//                                 ></div> {/* Circle */}
//                             </div>
//                         </div>
//                         <div className="bronze-tier flex flex-col mt-4 mx-auto w-4/5 ">
//                             {/* <img src={b1} alt="sponsor1" className="w-1/3 mx-auto"/> */}
//                             <div className='bronze-tier-header'>
//                                 <h2 style={{
//                                     fontSize: '2.5rem',
//                                     fontFamily: 'nunito',
//                                     textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
//                                     color: '#000',
//                                     fontWeight: 'bold'
//                                 }}>BRONZE SPONSORS</h2>
//                                 <div className="circle bg-[#CD7F32] rounded-full mx-auto mt-4"
//                                     style={{
//                                         width: '20rem',
//                                         height: '20rem',
//                                         // borderRadius: '1rem'
//                                     }}
//                                 ></div> {/* Circle */}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="w-full h-2 bg-[#303C55]"></div> {/* Green line */}

//                 <div className='newsletter-container flex flex-row text-center justify-center h-auto my-auto mx-auto py-12'>
//                     <div className='newsletter-placeholder w-1/4 h-96 mx-auto my-auto'
//                         style={{
//                             fontSize: '2rem',
//                             fontFamily: 'impact',
//                             // paddingTop: '60px',
//                             borderRadius: '50px',
//                             backgroundImage: `url(${nL_img})`,
//                             backgroundSize: 'cover',
//                             backgroundPosition: 'center',
//                             backgroundRepeat: 'no-repeat',
//                             border: '2px solid #004D40',
//                             boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)'
//                         }}>

//                     </div>
//     <div className='newsletter-info-card w-2/4 flex flex-col'>
//         <div className="contact-head flex flex-col text-center justify-center mx-auto my-auto">
//             <h1 style={{
//                 fontSize: '5rem',
//                 fontFamily: 'impact',
//                 color: '#303C55',
//                 paddingTop: '60px',
//                 textShadow: '2px 2px 9px rgba(0, 0, 0, 0.5)'
//             }}>
//                 NEWSLETTER
//             </h1>
//         </div>
//         {/* <div className='newsletter-header text-center flex flex-row mx-auto my-auto h-4/5'>
// <div className='newsletter-header-img text-center mx-auto my-auto'>
//     <img src={p1} alt="newsletter-header" className= "w-2/2 my-auto mx-auto" style={{borderRadius: '70px'}}/>
// </div>
// </div> */}

//         <div className='newsletter-info text center w-3/4 mx-auto my-auto h-3/5'>
//             <p className="newsletter-info text-center dark:text-white text-lg"
//                 style={{
//                     fontSize: '1.2rem',
//                     lineHeight: '1.5',
//                     fontFamily: 'nunito',

//                 }}>The NSBE UCalgary Newsletter is a monthly publication that keeps members informed and engaged with personal messages from the chapter president, highlights of events like workshops and competitions, and career development tips.
//                 It also shares updates on partnerships, sponsorships, and alumni news, maintaining strong community connections.
//                 This comprehensive newsletter supports members' academic and professional growth, fostering a sense of community and promoting active participation in NSBE activities.</p>
//         </div>

//         <div className='newsletter-button text-center w-4/5 mx-auto my-auto py-6 h-1/5'>
//             <button type="button" className=" transition hover:-translate-y-1 hover:scale-80 ease-in-out delay-100 text-white bg-emerald-900 font-extrabold text-xl tracking-wider hover:shadow-xl hover:bg-[#303C55] font-medium px-6 py-4 text-center dark:bg-teal-700 dark:hover:bg-teal-900"
//                 style={{ borderRadius: "30px" }}><Link to="https://mailchi.mp/ffc3b0345e78/nsbe-ucalgary-newsletter" target="_blank"> <i className="fas fa-envelope mr-2"></i>  Subscribe to NSBE-UOfC Newsletter</Link></button>
//         </div>
//     </div>
//     </div>
// </div>
//             <Footer />

//         </>

//     );

// };

// export default Home;

// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import Footer from './Footer';

// // Import images
// import b1 from '../images/i1.png';
// import b2 from '../images/i2.png';
// import b3 from '../images/i3.png';
// import b4 from '../images/i4.png';

// import i1 from '../images/icon_ae.gif';
// import i2 from '../images/icon2.png';
// import i3 from '../images/icon3.png';

// import nL_img from '../assets/nsbe_logo.jpg';

// const images = [b1, b2, b3, b4];

// function Home() {
//     const [backgroundImage, setBackgroundImage] = useState("");

//     useEffect(() => {
//         let counter = 0;
//         const interval = setInterval(() => {
//             setBackgroundImage(images[counter]);
//             counter = (counter + 1) % images.length;
//         }, 7000);

//         return () => clearInterval(interval);
//     }, []);

//     return (
//         <>
//             <div className="bg-gray-50">
//                 {/* Hero Section */}
//                 <section className="section1 mb-24" id='about'
//                     style={{
//                         backgroundImage: `linear-gradient(rgba(1.5,0,0,1.5),rgba(0,0,0,0.4)), url(${backgroundImage})`,
//                         backgroundSize: 'cover',
//                         backgroundPosition: 'center',
//                         backgroundRepeat: 'no-repeat',
//                         height: '100vh',
//                         display: 'flex',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         margin: 'auto'
//                     }}>
//                     <div className="about-container w-full max-w-7xl mx-auto my-auto px-4">
//                         <h1 className="font-extrabold text-center text-5xl sm:text-6xl md:text-7xl"
//                             style={{
//                                 color: '#004D40',
//                                 margin: '10px',
//                                 justifyContent: 'center',
//                                 display: 'flex',
//                                 lineHeight: '1.0',
//                                 fontFamily: 'impact',
//                                 textShadow: '2px 2px 9px rgba(0, 0, 0, 0.9)'
//                             }}>
//                             NATIONAL SOCIETY OF BLACK ENGINEERS
//                         </h1>
//                         <h1 className="mb-4 text-3xl sm:text-4xl md:text-5xl text-center text-gray-900"
//                             style={{
//                                 color: 'white',
//                                 margin: '10px',
//                                 justifyContent: 'center',
//                                 display: 'flex',
//                                 lineHeight: '1.0',
//                                 fontFamily: 'impact',
//                                 textShadow: '2px 2px 9px rgba(0, 0, 0, 0.9)'
//                             }}>
//                             UCALGARY CHAPTER
//                         </h1>
//                         <div className="join-info text-center flex flex-col sm:flex-row gap-4 sm:gap-8 justify-center">
//                             <div className="become-member text-center text-lg bg-[#303C55] transition hover:-translate-y-1 hover:scale-80 ease-in-out delay-100 text-white bg-emerald-900 font-extrabold text-xl tracking-wider hover:shadow-xl hover:bg-[#004D40] font-medium px-6 py-4 rounded-2xl shadow-lg"
//                                 style={{
//                                     fontSize: "1.5rem",
//                                     fontFamily: "nunito",
//                                     boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.9)',
//                                     fontWeight: "bold",
//                                 }}>
//                                 <Link to="https://docs.google.com/forms/d/e/1FAIpQLSfplx30HQHxu1OfNIjlSgTWFdMEh1KOWg6SuE553nPf1osPXA/viewform" target="_blank"> <i className="fas fa-users mr-2"></i>Become A Member </Link>
//                             </div>
//                             <div className="become-sponsor text-center text-lg bg-[#303C55] transition hover:-translate-y-1 hover:scale-80 ease-in-out delay-100 text-white bg-emerald-900 font-extrabold text-xl tracking-wider hover:shadow-xl hover:bg-[#004D40] font-medium px-6 py-4 rounded-2xl shadow-lg"
//                                 style={{
//                                     fontSize: "1.5rem",
//                                     fontFamily: "nunito",
//                                     boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.9)',
//                                     fontWeight: "bold",
//                                 }}>
//                                 <Link to="/GetInvolved"> <i className="fas fa-handshake mr-2"></i>Become A Sponsor</Link>
//                             </div>
//                         </div>
//                     </div>
//                 </section>

//                 {/* Dark Green Line */}
//                 <div className="w-full h-4 bg-[#004D40]"></div>

//                 {/* What We Offer Section */}
//                 <div className="offer-head flex flex-col text-center justify-center mx-auto my-auto">
//                     <h1 className="text-4xl sm:text-5xl md:text-6xl font-extrabold text-[#303C55] px-4"
//                         style={{
//                             paddingTop: '60px',
//                             textShadow: '2px 2px 9px rgba(0, 0, 0, 0.5)'
//                         }}>
//                         WHAT WE OFFER
//                     </h1>
//                     <div className="w-1/3 h-1 bg-[#303C55] mx-auto mt-4"></div>
//                 </div>
//                 <div className="flex flex-col sm:flex-row gap-8 mx-auto py-12">
//                     <div className="flex flex-col mt-4 mx-auto w-full max-w-md">
//                         <img src={i2} alt="icon2" className="w-1/2 mx-auto" />
//                         <div className='offer-head text-center mx-auto my-auto'>
//                             <h2 className="text-2xl sm:text-3xl font-bold text-[#004D40]"
//                                 style={{
//                                     textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
//                                 }}>Professional Development</h2>
//                         </div>
//                         <div>
//                             <p className='offer-info text-center w-full max-w-lg mx-auto' style={{ fontFamily: 'nunito' }}>
//                                 Innovation at the National Society of Black Engineers (NSBE) at the University of Calgary involves fostering a collaborative environment where diverse perspectives come together to develop creative solutions to address challenges and opportunities in the engineering field. This includes the application of engineering principles to solve real-world problems.
//                             </p>
//                         </div>
//                     </div>
//                     <div className="flex flex-col mt-4 mx-auto w-full max-w-md">
//                         <img src={i1} alt="icon1" className="w-full mx-auto" />
//                         <div className='offer-head text-center mx-auto my-auto'>
//                             <h2 className="text-2xl sm:text-3xl font-bold text-[#004D40]"
//                                 style={{
//                                     textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
//                                 }}>Academic Excellence</h2>
//                         </div>
//                         <div className='offer-info text-center w-full max-w-lg mx-auto' style={{ fontFamily: 'nunito' }}>
//                             <p>
//                                 Academic Excellence in the National Society of Black Engineers (NSBE) at the University of Calgary refers to the efforts of the NSBE chapter to promote the highest standards of academic excellence in engineering. This includes the application of engineering principles to solve real-world problems pertaining to the Black community.
//                             </p>
//                         </div>
//                     </div>
//                     <div className="flex flex-col mt-4 mx-auto w-full max-w-md">
//                         <img src={i3} alt="icon3" className="w-1/2 mx-auto" />
//                         <div className='offer-head text-center mx-auto my-auto'>
//                             <h2 className="text-2xl sm:text-3xl font-bold text-[#004D40]"
//                                 style={{
//                                     textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
//                                 }}>Diversity and Community</h2>
//                         </div>
//                         <div>
//                             <p className='offer-info text-center w-full max-w-lg mx-auto' style={{ fontFamily: 'nunito' }}>
//                                 Diversity and Community in the National Society of Black Engineers (NSBE) at the University of Calgary highlight the importance of inclusivity and fostering a sense of belonging among members. The chapter strives to create an environment where diverse perspectives are valued and celebrated, and where members can connect with one another and with the broader engineering community.
//                             </p>
//                         </div>
//                     </div>
//                 </div>

//                 {/* Sponsors Section */}
//                 <div className="sponsors flex flex-col text-center justify-center mx-auto my-auto bg-[#303C55]">
//                     <div className="sponsor-head flex flex-col text-center justify-center mx-auto my-auto">
//                         <h1
//                             className="text-white"
//                             style={{
//                                 fontSize: "5rem",
//                                 fontFamily: "impact",
//                                 paddingTop: "60px",
//                                 textShadow: "2px 2px 9px rgba(0, 0, 0, 0.5)",
//                             }}
//                         >
//                             OUR SPONSORS
//                         </h1>
//                     </div>
//                     <div className="w-1/3 h-1 bg-[#303C55] mx-auto mt-4"></div>
//                     <div
//                         className="sponsor-logos grid grid-cols-1 md:grid-cols-2 gap-4 justify-center items-start py-12"
//                         style={{ height: "auto" }}
//                     >
//                         {/* Platinum Sponsors */}
//                         <div className="platinum-tier flex flex-col mt-3 mx-auto w-full sm:w-4/5 md:w-3/4 lg:w-2/3">
//                             <div className="platinum-tier-header">
//                                 <h2
//                                     className="text-black text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold"
//                                     style={{
//                                         fontFamily: "nunito",
//                                         textShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
//                                     }}
//                                 >
//                                     PLATINUM SPONSORS
//                                 </h2>
//                                 <div
//                                     className="circle bg-[#E5E4E2] rounded-full mx-auto mt-4"
//                                     style={{
//                                         width: "15rem",
//                                         height: "15rem",
//                                     }}
//                                 ></div>
//                             </div>
//                         </div>

//                         {/* Gold Sponsors */}
//                         <div className="gold-tier flex flex-col mt-4 mx-auto w-full sm:w-4/5 md:w-3/4 lg:w-2/3">
//                             <div className="gold-tier-header">
//                                 <h2
//                                     className="text-black text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold"
//                                     style={{
//                                         fontFamily: "nunito",
//                                         textShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
//                                     }}
//                                 >
//                                     GOLD SPONSORS
//                                 </h2>
//                                 <div
//                                     className="circle bg-[#FFD700] rounded-full mx-auto mt-4"
//                                     style={{
//                                         width: "15rem",
//                                         height: "15rem",
//                                     }}
//                                 ></div>
//                             </div>
//                         </div>

//                         {/* Silver Sponsors */}
//                         <div className="silver-tier flex flex-col mt-4 mx-auto w-full sm:w-4/5 md:w-3/4 lg:w-2/3">
//                             <div className="silver-tier-header">
//                                 <h2
//                                     className="text-black text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold"
//                                     style={{
//                                         fontFamily: "nunito",
//                                         textShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
//                                     }}
//                                 >
//                                     SILVER SPONSORS
//                                 </h2>
//                                 <div
//                                     className="circle bg-[#C0C0C0] rounded-full mx-auto mt-4"
//                                     style={{
//                                         width: "15rem",
//                                         height: "15rem",
//                                     }}
//                                 ></div>
//                             </div>
//                         </div>

//                         {/* Bronze Sponsors */}
//                         <div className="bronze-tier flex flex-col mt-4 mx-auto w-full sm:w-4/5 md:w-3/4 lg:w-2/3">
//                             <div className="bronze-tier-header">
//                                 <h2
//                                     className="text-black text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold"
//                                     style={{
//                                         fontFamily: "nunito",
//                                         textShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
//                                     }}
//                                 >
//                                     BRONZE SPONSORS
//                                 </h2>
//                                 <div
//                                     className="circle bg-[#CD7F32] rounded-full mx-auto mt-4"
//                                     style={{
//                                         width: "15rem",
//                                         height: "15rem",
//                                     }}
//                                 ></div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 {/* Newsletter Section */}
//                 <div className="newsletter-info-card w-full sm:w-3/4 md:w-2/4 flex flex-col mx-auto mt-12">
//                     <div className="contact-head flex flex-col text-center justify-center mx-auto my-auto">
//                         <h1
//                             className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl"
//                             style={{
//                                 fontFamily: "impact",
//                                 color: "#303C55",
//                                 paddingTop: "60px",
//                                 textShadow: "2px 2px 9px rgba(0, 0, 0, 0.5)",
//                             }}
//                         >
//                             NEWSLETTER
//                         </h1>
//                     </div>
//                     <div className="newsletter-info text-center w-full mx-auto my-auto h-auto mt-8">
//                         <p
//                             className="text-base sm:text-lg md:text-xl lg:text-2xl"
//                             style={{
//                                 lineHeight: "1.5",
//                                 fontFamily: "nunito",
//                             }}
//                         >
//                             The NSBE UCalgary Newsletter is a monthly publication that keeps
//                             members informed and engaged with personal messages from the chapter
//                             president, highlights of events like workshops and competitions, and
//                             career development tips. It also shares updates on partnerships,
//                             sponsorships, and alumni news, maintaining strong community
//                             connections. This comprehensive newsletter supports members'
//                             academic and professional growth, fostering a sense of community and
//                             promoting active participation in NSBE activities.
//                         </p>
//                     </div>
//                     <div className="newsletter-button text-center w-full mx-auto my-auto py-6 h-1/5">
//                         <button
//                             type="button"
//                             className="transition hover:-translate-y-1 hover:scale-80 ease-in-out delay-100 text-white bg-emerald-900 font-extrabold text-lg sm:text-xl md:text-2xl lg:text-3xl tracking-wider hover:shadow-xl hover:bg-[#303C55] font-medium px-6 py-4 text-center"
//                             style={{ borderRadius: "30px" }}
//                         >
//                             <a
//                                 href="https://mailchi.mp/ffc3b0345e78/nsbe-ucalgary-newsletter"
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                             >
//                                 <i className="fas fa-envelope mr-2"></i> Subscribe to NSBE-UOfC
//                                 Newsletter
//                             </a>
//                         </button>
//                     </div>
//                 </div>

//                 <Footer />
//             </div>
//         </>
//     );
// }

// export default Home;

import React, { useState, useEffect } from "react";
import bg2 from "../images/i2.png";
import bg1 from "../images/i1.png";
import bg3 from "../images/i3.png";
import bg4 from "../images/i4.png";
import Footer from "./Footer";

import i1 from '../images/icon_ae.gif';
import i2 from '../images/icon2.png';
import i3 from '../images/icon3.png';

import stantec from "../images/Stantec-Logo.png";
import brainstem from "../images/Brainstem-Logo.png";
import NewYear from "../components/Confetti/NewYear";

export default function HomePage() {
    const images = [bg1, bg2, bg3, bg4];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showConfetti, setShowConfetti] = useState(true)

    useEffect(() => {
        const timer = setInterval(() => {
            setShowConfetti(false)
        }, 7000)

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000); // Change image every 5 seconds
        return () => clearInterval(interval); // Cleanup on unmount
    }, [images.length]);
    return (
        <>
            {/* {showConfetti && <NewYear />} */}
    {/* Hero Section */}
    <div
        className="w-screen min-h-screen bg-cover bg-center transition-all duration-1000"
        style={{
            backgroundImage: `url(${images[currentIndex]})`,
            transition: "background-image 1s ease-in-out",
        }}
    >
        <div className="flex flex-col items-center justify-center h-screen max-w-7xl mx-auto text-center text-white px-4">
            <h1 className="text-3xl sm:text-5xl md:text-7xl bg-clip-text text-[#004d40] font-[impact]">
                NATIONAL SOCIETY OF BLACK ENGINEERS
            </h1>
            <h2 className="text-2xl sm:text-4xl md:text-5xl mt-4 bg-clip-text font-[impact]">
                UCALGARY CHAPTER
            </h2>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mt-6">
                <button
                    className="bg-[#004d40] text-white px-4 sm:px-6 py-2 rounded-lg text-sm sm:text-lg font-medium hover:scale-105 transition-transform"
                    onClick={() =>
                        window.open(
                            "https://docs.google.com/forms/d/e/1FAIpQLSfplx30HQHxu1OfNIjlSgTWFdMEh1KOWg6SuE553nPf1osPXA/viewform",
                            "_blank"
                        )
                    }
                >
                    <i className="fas fa-users mr-2"></i>Become A Member
                </button>
                <button
                    className="bg-[#004d40] text-white px-4 sm:px-6 py-2 rounded-lg text-sm sm:text-lg font-medium hover:scale-105 transition-transform"
                    onClick={() => (window.location.href = "/get-involved")}
                >
                    <i className="fas fa-handshake mr-2"></i>Become A Sponsor
                </button>
            </div>
        </div>
    </div>

    {/* What We Offer Section */}
    <div className="w-screen min-h-screen bg-cover bg-center">
        <div className="flex flex-col items-center justify-center h-auto max-w-7xl mx-auto text-center text-white px-4 py-8">
            <h2 className="text-2xl sm:text-4xl md:text-6xl font-extrabold bg-clip-text text-[#303c55] drop-shadow-lg">
                WHAT WE OFFER
            </h2>
            <div className="w-16 h-1 bg-[#303C55] mx-auto mt-4"></div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-10">
                {/* Card 1 */}
                <div className="bg-white bg-opacity-80 p-6 rounded-lg shadow-lg text-black hover:scale-105 hover:shadow-xl transition-transform duration-300">
                    <img
                        src={i3}
                        alt="icon3"
                        className="w-1/2 mx-auto mb-4"
                    />
                    <h3 className="text-lg sm:text-xl font-bold text-[#004d40] mb-2">
                        DIVERSITY & COMMUNITY
                    </h3>
                    <p className="text-sm sm:text-base leading-relaxed">
                        Diversity and Community in the National Society
                                of Black Engineers (NSBE) at the University of
                                Calgary highlight the importance of inclusivity
                                and fostering a sense of belonging among
                                members. The chapter strives to create an
                                environment where diverse perspectives are
                                valued and celebrated, and where members can
                                connect with one another and with the broader
                                engineering community.
                    </p>
                </div>
                {/* Card 2 */}
                        <div className="bg-white bg-opacity-70 p-6 rounded-lg shadow-lg text-black hover:scale-105 hover:shadow-xl transition-transform duration-300">
                            <img
                                src={i1}
                                alt="icon1"
                                className="w-full mx-auto mb-4"
                            />
                            <h3 className="text-xl font-bold text-[#004d40] mb-2">
                                Academic Excellence
                            </h3>
                            <p className="text-sm leading-relaxed">
                                Academic Excellence in the National Society of
                                Black Engineers (NSBE) at the University of
                                Calgary refers to the efforts of the NSBE
                                chapter to promote the highest standards of
                                academic excellence in engineering. This
                                includes the application of engineering
                                principles to solve real-world problems
                                pertaining to the Black community.
                            </p>
                        </div>
                        {/* Card 3 */}
                        <div className="bg-white bg-opacity-70 p-6 rounded-lg shadow-lg text-black hover:scale-105 hover:shadow-xl transition-transform duration-300">
                            <img
                                src={i2}
                                alt="icon2"
                                className="w-1/2 mx-auto mb-4"
                            />
                            <h3 className="text-xl font-bold text-[#004d40] mb-2">
                                Professional Development
                            </h3>
                            <p className="text-sm leading-relaxed">
                                Innovation at the National Society of Black
                                Engineers (NSBE) at the University of Calgary
                                involves fostering a collaborative environment
                                where diverse perspectives come together to
                                develop creative solutions to address challenges
                                and opportunities in the engineering field. This
                                includes the application of engineering
                                principles to solve real-world problems.
                            </p>
                        </div>
            </div>
        </div>
    </div>

    {/* Sponsors Section */}
    <div className="w-screen min-h-screen bg-cover bg-center bg-theme-color">
        <div className="flex flex-col items-center justify-center h-full px-4 sm:px-6 py-8 max-w-5xl mx-auto text-center text-white">
            <h2 className="text-2xl sm:text-5xl md:text-6xl font-extrabold text-white drop-shadow-md font-[impact]">
                OUR SPONSORS
            </h2>
            <p className="text-sm sm:text-base mt-4 max-w-lg">
                We are proud to partner with organizations that support our
                mission.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-8 w-full">
                <img
                    src={stantec}
                    alt="Stantec"
                    className="w-full h-auto mx-auto transition-transform transform hover:scale-105"
                />
                <img
                    src={brainstem}
                    alt="Brainstem"
                    className="w-full h-auto mx-auto transition-transform transform hover:scale-105"
                />
            </div>
        </div>
    </div>

    {/* Newsletter Section */}
    <div className="w-full sm:w-3/4 lg:w-2/4 flex flex-col mx-auto mt-12 px-4">
        <div className="text-center">
            <h1 className="text-3xl sm:text-5xl md:text-6xl font-[impact] text-[#303C55] drop-shadow-lg">
                NEWSLETTER
            </h1>
        </div>
        <div className="text-center w-full mx-auto my-8">
            <p className="text-sm sm:text-lg md:text-xl leading-relaxed">
                The NSBE UCalgary Newsletter is a monthly publication
                that keeps members informed and engaged with personal
                messages from the chapter president, highlights of
                events like workshops and competitions, and career
                development tips. It also shares updates on
                partnerships, sponsorships, and alumni news, maintaining
                strong community connections. This comprehensive
                newsletter supports members' academic and professional
                growth, fostering a sense of community and promoting
                active participation in NSBE activities.
            </p>
        </div>
        <div className="text-center w-full mx-auto py-6">
            <button
                type="button"
                className="transition hover:-translate-y-1 hover:scale-105 text-white bg-emerald-900 font-bold text-base sm:text-xl px-6 py-3 rounded-full"
            >
                <a
                    href="https://mailchi.mp/ffc3b0345e78/nsbe-ucalgary-newsletter"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i className="fas fa-envelope mr-2"></i> Subscribe to NSBE-UOfC
                    Newsletter
                </a>
            </button>
        </div>
    </div>
    <Footer />
</>

    );
}
