import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { format } from "date-fns";
import "../../styles/Event.css";

const events = [
    {
        id: 1,
        title: "Winter Welcome Social",
        date: new Date(2025, 0, 24, 17),
    },
    {
        id: 2,
        title: "Study Night #1",
        date: new Date(2025, 1, 12, 17),
    },
    {
        id: 3,
        title: "Black Legacy Dinner",
        date: new Date(2025, 1, 27, 17),
    },
    {
        id: 4,
        title: "Prof Dev Workshop #2",
        date: new Date(2025, 2, 3, 16),
    },
    {
        id: 5,
        title: "Grad school & Academia Info Session",
        date: new Date(2025, 2, 12, 17),
    },
    {
        id: 6,
        title: "Study Night #3",
        date: new Date(2025, 2, 25, 17),
    },
    {
        id: 7,
        title: "Study Night #4 (study & snack)",
        date: new Date(2025, 3, 3),
    },
    {
        id: 8,
        title: "Exec & Volunteer End of Year Party",
        date: new Date(2025, 3, 28),
    },
];

const EventCalendar = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());

    return (
        <div className="bg-neon-color p-4">
            <Calendar
                onChange={setSelectedDate}
                value={selectedDate}
                tileContent={({ date, view }) => {
                    const dailyEvents = events.filter(
                        (event) =>
                            format(event.date, "yyyy-MM-dd") ===
                            format(date, "yyyy-MM-dd")
                    );

                    return dailyEvents.length > 0 ? (
                        <div className="event-details text-xs text-blue-900 mt-1">
                            {dailyEvents.map((event) => (
                                <div key={event.id} className="event-item">
                                    {event.title}
                                    {event.title === "Black Legacy Dinner" ? (
                                        <div className="star-container">
                                            <div className="star">
                                            </div>
                                        </div>
                                    ) : (
                                        <span>{event.title}</span>
                                    )}
                                </div>
                            ))}
                        </div>
                    ) : null;
                }}
                tileClassName={({ date, view }) => {
                    const isSelected =
                        format(date, "yyyy-MM-dd") ===
                        format(selectedDate, "yyyy-MM-dd");
                    return isSelected ? "bg-blue-100" : "";
                }}
            />
        </div>
    );
};

export default EventCalendar;