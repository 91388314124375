import { React, useEffect, useState } from "react";
import photo1 from "../assets/photos/getInvolved/getInv.png";
import photo3 from "../assets/photos/getInvolved/DSCN2082.JPG";

import photo6 from "../assets/photos/getInvolved/carousel/gi01.jpg";
import photo8 from "../assets/photos/getInvolved/carousel/gi3.jpg";
import photo9 from "../assets/photos/getInvolved/carousel/gi4.jpg";



import Footer from './Footer';

const GetInvolved = () => {
  const infoData = [
    {
      header: "ACADEMIC EXCELLENCE PROGRAMS",
      info: ["Mentorship Program", "Study Night and Workshops", "Fall Regional Conference", "Annual NSBE Convention"]
    },
    {
      header: "HIGH SCHOOL OUTREACH PROGRAMS",
      info: ["Brain Stem Alliance", "While She is True"]
    },
    {
      header: "PROFESSIONAL DEVELOPMENT PROGRAMS",
      info: ["Career Fair", "Professional Development Workshops"]
    }
  ];

  const columnTwoData = [
    {
      header: "Champion Diversity and Inclusion",
      description: "By sponsoring our chapter, you demonstrate your commitment to diversity and inclusion in STEM fields. This not only supports a more equitable workforce but also drives innovation and creativity within your organization."
    },
    {
      header: "Access to Top Talent",
      description: "As a sponsor, you gain direct access to a pool of over a 100+ of some of the brightest and most ambitious graduate and undergraduate students in the engineering, technology, science and math fields."
    },
    {
      header: "Support a prestigious organization",
      description: "Partnering with NSBE, a well-respected and established organization, and support innovative programs and initiatives that aligns your brand with excellence in engineering and technology."
    }
  ];

  const sponsorLevels = [
    {
      heading: "PLATINUM",
      color: "bg-[#E5E4E2]",
      description: ["Logo placement on our official website", "Company logo on banners and merchandise", "Unlimited promotional efforts on social media, newsletter and our official website", "4 Representatives invited to prestigious Black Legacy Dinner"],
      price: "$5000"
    },
    {
      heading: "GOLD",
      color: "bg-[#FFD700]",
      description: ["Logo placement on the official website", "Company logo on banners and merchandise", "3 promotional efforts on social media, newsletter and our official website", "3 Representatives invited to prestigious Black Legacy Dinner"],
      price: "$2500"
    },
    {
      heading: "SILVER",
      color: "bg-[#C0C0C0]",
      description: ["Logo placement on our official website", "2 promotional efforts on social media, newsletter and our official website", "2 Representatives invited to prestigious Black Legacy Dinner"],
      price: "$1000"
    },
    {
      heading: "BRONZE",
      color: "bg-[#CD7F32]",
      description: ["Logo placement on our official website", "Promotional effort on social media, newsletter and our official website", "1 representative invited to Black Legacy Dinner"],
      price: "$750"
    }
  ];
  const carousel = [
    { id: 1, src: photo6 },
    { id: 3, src: photo8 },
    { id: 4, src: photo9 },
  ]
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === carousel.length - 1 ? 0 : prevIndex + 1
      );
    }, 10000); // Change photo every 5 seconds
    return () => clearInterval(interval); // Cleanup on unmount
  }, [carousel.length]);

  const handlePrevious = () => {
    setCurrentIndex(
      currentIndex === 0 ? carousel.length - 1 : currentIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex(
      currentIndex === carousel.length - 1 ? 0 : currentIndex + 1
    );
  };

  return (
    <div className="bg-gray-50 text-gray-800">
      <div className="max-w-full mx-auto bg-[#F1F0E8]">
        <h1 className="page-header text-4xl font-bold text-center py-8">Get Involved</h1>

        {/* First Section */}
        <div className="flex flex-col lg:flex-row bg-[#F1F0E8] rounded-lg mx-auto w-11/12 my-8 lg:my-12">
          {/* Image Section */}
          <div className="w-full lg:w-1/2 h-64 lg:h-auto overflow-hidden rounded-lg shadow-lg">
            <img
              src={photo1}
              alt="Membership"
              className="w-full h-full object-cover rounded-lg"
            />
          </div>

          {/* Content Section */}
          <div className="w-full lg:w-1/2 p-6 lg:p-10 flex text-right flex-col justify-center">
            <h2 className="text-3xl font-bold text-gray-800 mb-4 lg:mb-6">Join Our Vibrant Community</h2>
            <p className="text-gray-600 text-lg lg:text-xl leading-relaxed mb-4 lg:mb-6">
              Become a part of an exciting and inclusive community of like-minded individuals. Our club offers opportunities for personal growth, professional networking, and meaningful contributions to impactful initiatives. Let's build something amazing together!
            </p>
            <div className="flex justify-end">
              <button
                className="bg-[#004d40] w-full lg:w-auto h-16 text-white font-bold text-lg lg:text-xl rounded-lg shadow-lg transition-transform transform hover:scale-95 px-8"
                onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSfplx30HQHxu1OfNIjlSgTWFdMEh1KOWg6SuE553nPf1osPXA/viewform", "_blank")}
              >
                Become a Member
              </button>
            </div>
          </div>
        </div>

        {/* Programs Section */}
        <div className="bg-theme-color py-8 lg:py-16">
          <h2 className="text-3xl lg:text-4xl font-bold text-white text-center mb-6 lg:mb-12">Opportunities</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-4 lg:px-8">
            {infoData.map((item, index) => (
              <div key={index} className="p-6 bg-[#F1F0E8] rounded-lg shadow-md">
                <h3 className="text-xl lg:text-2xl leading-relaxed font-semibold mb-4">{item.header}</h3>
                <ul className="list-disc ml-6 text-base lg:text-lg">
                  {item.info.map((info, idx) => (
                    <li key={idx} className="mb-2">{info}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          {/* Image Carousel */}
          <div className="relative w-11/12 lg:w-3/4 h-64 lg:h-full mx-auto mt-8 lg:mt-12 overflow-hidden rounded-lg shadow-lg">
            <div className="flex transition-transform duration-500 ease-in-out" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
              {carousel.map((photo, index) => (
                <img key={index} src={photo.src} alt={`Slide ${index}`} className="w-full h-full object-fit" />
              ))}
            </div>

            {/* Navigation Buttons */}
            <button
              onClick={handlePrevious}
              className="absolute top-1/2 left-4 -translate-y-1/2 bg-gray-800 text-white rounded-full p-2 shadow-md hover:bg-gray-700"
            >
              &#8592;
            </button>
            <button
              onClick={handleNext}
              className="absolute top-1/2 right-4 -translate-y-1/2 bg-gray-800 text-white rounded-full p-2 shadow-md hover:bg-gray-700"
            >
              &#8594;
            </button>

            {/* Dots Navigation */}
            <div className="absolute bottom-4 w-full flex justify-center space-x-2">
              {carousel.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentIndex(index)}
                  className={`w-3 h-3 rounded-full ${currentIndex === index ? "bg-gray-800" : "bg-gray-400"}`}
                ></button>
              ))}
            </div>
          </div>
        </div>

        {/* Second Section */}
        <div className="flex flex-col lg:flex-row bg-[#F1F0E8] rounded-lg mx-auto w-11/12 my-8 lg:my-12">
          {/* Content Section */}
          <div className="w-full lg:w-1/2 p-6 lg:p-10 flex flex-col justify-center">
            <h2 className="text-3xl font-bold text-gray-800 mb-4 lg:mb-6">Volunteer</h2>
            <p className="text-gray-600 text-lg lg:text-xl leading-relaxed mb-4 lg:mb-6">
              Make a difference by volunteering your time and skills with our club. Help us organize impactful events, mentor aspiring professionals, and contribute to community initiatives. Volunteering is a great way to give back, build leadership skills, and connect with passionate individuals. Together, we can create meaningful change and grow both personally and professionally!
            </p>
            <div className="flex justify-begin">
              <button
                className="bg-[#004d40] w-full lg:w-auto h-16 text-white font-bold text-lg lg:text-xl rounded-lg shadow-lg transition-transform transform hover:scale-95 px-8"
                onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSfoQQ6El6F-AC4kXBO4qUR3lOph7UY1aDiuVH-aRndC9d3kKA/viewform", "_blank")}
              >
                Volunteer with Us
              </button>
            </div>
            
          </div>

          {/* Image Section */}
          <div className="w-full lg:w-1/2 h-64 lg:h-auto overflow-hidden rounded-lg shadow-lg">
            <img src={photo3} alt="Volunteer" className="w-full h-full object-cover rounded-lg" />
          </div>
        </div>

        {/* Sponsorship Section */}
        <div className="mb-8 lg:mb-16 mt-6">
          <h2 className="text-3xl font-bold text-center mb-6 lg:mb-8">Sponsorship Opportunities</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-4 lg:px-8">
            {columnTwoData.map((item, index) => (
              <div key={index} className="p-6 bg-white rounded-lg shadow-md">
                <h3 className="text-xl lg:text-2xl font-semibold mb-4">{item.header}</h3>
                <p className="text-base lg:text-lg">{item.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Sponsor Levels Section */}
        <div className="bg-theme-color py-8 lg:py-16">
          <h2 className="text-3xl lg:text-4xl font-bold text-white text-center mb-6 lg:mb-12">Sponsorship Levels</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 px-4 lg:px-8">
            {sponsorLevels.map((level, index) => (
              <div key={index} className={`p-6 rounded-lg text-black ${level.color}`}>
                <h3 className="text-xl lg:text-2xl font-semibold mb-4">{level.heading}</h3>
                <p className="text-lg lg:text-xl mb-4 font-bold">{level.price}</p>
                <ul className="list-disc ml-4 text-base lg:text-lg">
                  {level.description.map((desc, idx) => (
                    <li key={idx} className="mb-2">{desc}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
      <><Footer /></>
      
    </div>
  );
};

export default GetInvolved;